<template>
  <v-container id="contacts" tag="section">
     <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="`headline green white--text">
     
          Contato
           <v-spacer />
      
          <v-btn text @click="clearFields()"><v-icon large color="white">mdi-close</v-icon></v-btn>
      
        </v-card-title>
        <v-divider></v-divider>
        <v-text-field v-model="name" class="ma-5" label="Nome" outlined readonly></v-text-field>
        <v-text-field v-model="email" class="ma-5" label="E-mail" outlined readonly></v-text-field>
        <v-text-field v-model="phone" class="ma-5" label="Telefone" outlined readonly></v-text-field>
        <!-- <v-text-field v-model="message" class="ma-5" label="Mensagem" outlined readonly></v-text-field> -->
        <v-col cols="12">
        <v-textarea
          outlined
          class="pa-1"
          name="input-7-4"
          rows="5"
          label="Mensagem"
          readonly
          :value="message"
        ></v-textarea>

        </v-col>
        <v-divider></v-divider>

      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Main
    ============================================================-->
    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />
  </v-container>
</template>

<script>
import contactsService from "../../../../service/contactsService";

export default {
  name: "contacts",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => ({
    title: "Contatos Cadastrados",
    columns: ["Nome", "Email", "Data", "Ações"],
    actionsOptions: ["preview"],
    linesData: [],
    contacts: [],
    openDialog: false,
    overlay:false,
    name: "",
    email: "",
    phone: "",
    message: ""
  }),
  methods: {
    action($event) {
      switch ($event.type) {
        case "preview":
          this.preview($event.id);
          break;
        default:
          break;
      }
    },
    getAll() {
      this.overlay = true;
      contactsService.get({}).then(res => {
        this.overlay = false;
        this.contacts = res.data;
        this.linesData = res.data.map(contacts => {
          return {
            id: contacts.id,
            email: contacts.email,
            lines: [
              { width: 30, name: contacts.name },
              { width: 30, name: contacts.email },
              {
                width: 15,
                name: this.formatDate(new Date(contacts.created_at))
              }
            ]
          };
        });
        this.overlay=false
      });
    },
    preview(id) {
      const data = this.contacts.filter(res => res.id === id)[0];
      this.name = data.name;
      this.email = data.email;
      this.phone = data.phone;
      this.message = data.message;
      this.openDialog = true;
    },
    formatDate(date) {
      const dateConvert = new Date()
      if (!date) return null;
      const insetZero = (num)=>num<=9?`0${num}`:num
      
      return `${insetZero(dateConvert.getDate())}/${insetZero(dateConvert.getMonth())}/${dateConvert.getFullYear()}`;
    },
    clearFields() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.openDialog = false;
    }
  },
  mounted() {
    this.getAll();
  }
};
</script>
